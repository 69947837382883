/* .main {
    background-color: #fff;
    border-radius: 15px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 20px;
    transition: transform 0.2s;
    width: 600px;
  } */
  
  .main:hover {
    transform: scale(1.05);
  }
  
  .gfg {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    color: #F6921E;
  }
  
  .custom-wrapper {
    margin: 0;
    width: 70%;
    padding: 0px 25px 40px;
    position: relative;
    left: 63px;
  }
  
  .header h2 {
    font-size: 30px;
    color: #F6921E;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .price-input-container {
    width: 100%;
  }
  
  .price-input .price-field {
    display: flex;
    margin-bottom: 22px;
  }
  
  .price-field span {
    margin-right: 10px;
    margin-top: 6px;
    font-size: 17px;
  }
  
  .price-field input {
    flex: 1;
    height: 35px;
    font-size: 15px;
    font-family: "DM Sans", sans-serif;
    border-radius: 9px;
    text-align: center;
    border: 0px;
    background: #e4e4e4;
  }
  
  .price-input {
    width: 100%;
    font-size: 19px;
    color: #555;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  .slider-container {
    width: 100%;
    height: 6px;
    position: relative;
    background: #e4e4e4;
    border-radius: 5px;
  }
  
  .slider-container .price-slider {
    height: 100%;
    position: absolute;
    border-radius: 5px;
    background: #F6921E;
  }
  
  .range-input {
    position: relative;
  }
  
  .range-input input {
    position: absolute;
    width: 100%;
    height: 5px;
    background: none;
    top: -5px;
    pointer-events: none;
    cursor: pointer;
    -webkit-appearance: none;
  }
  
  input[type="range"]::-webkit-slider-thumb {
    height: 18px;
    width: 18px;
    border-radius: 70%;
    background: #555;
    pointer-events: auto;
    -webkit-appearance: none;
  }
  
  @media screen and (max-width: 768px) {
    .main {
      width: 80%;
      margin-right: 5px;
    }
  
    .custom-wrapper {
      width: 100%;
      left: 0;
      padding: 0 10px;
    }
  
    .projtitle {
      width: 100%;
      position: relative;
      right: 26px;
    }
  
    .price-input {
      flex-direction: column;
      align-items: center;
    }
  
    .price-field {
      margin-bottom: 10px;
    }
}