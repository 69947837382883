.table {
  width: 100%;
  border-collapse: collapse;
}

.table th, .table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

.table th {
  background-color: #f2f2f2;
}

@media (min-width: 991px) {
.table-responsive {
  overflow-x: auto;
  max-width: 100%;
  margin-bottom: 1rem;
  margin-top: 1rem;
  padding :0 10rem;
}
}

@media (min-width: 991px) {
  .table-responsive {
    overflow-x: auto;
    max-width: 100%;
    margin-bottom: 1rem;
    margin-top: 1rem;
    
  }
}