.cart-item:not(:last-child) {
    border-bottom: 1px solid #d4d4d6;
}

.cr {
    display: flex;
    flex-direction: column;
    gap: 2em;
    align-items: flex-end;
    margin-top: -40px;
}
.cart-btn {
    padding: 16px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.adjust-qty {
    display: flex
}

.cart-item-qty {
    min-width: 2em;
    width: auto;
    /* border: 1px solid; */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto 5px;
}

.cart-rm-item {
    display: flex;
    justify-content: flex-end;
    /* margin-top: 12px; */
    /* padding-top: 12px; */
}

.cart-rm-item:hover {
    color: #e50b0b;
}

.cart-summary-item {
    display: flex;
    color: black;
}

.cart-summary-item-label {
    flex: 1;
    display: flex;
    justify-content: flex-start;
}

.cart-summary-item-content {
    flex: 2;
    display: flex;
    justify-content: flex-end;
    font-weight: 600;
    color: inherit;
}

.no-bg::before {
    background-image: none;
}

.move-bg::before {
    background-position: calc(-1000px);
}