/* Marquee Container */
.marquee-container {
    width: 90%; /* Full width */
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: #f8f9fa;   Light background */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0px 0px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px; 
  }
  
  /* Marquee */
  .marquee {
    display: flex;
    width: 100%;
    justify-content: space-around;
    animation: scroll 10s linear infinite; /* Smooth scrolling */
    white-space: nowrap;
  }
  
  .marquee span {
    font-size: 2rem;
    font-weight: bold;
    color: #F6921E; /* Vibrant color */
    text-transform: uppercase;
    padding: 0 50px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);
  }
  
  /* Keyframe for scrolling */
  @keyframes scroll {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  /* Responsive Adjustments */
  @media screen and (max-width: 768px) {
    .marquee span {
      font-size: 1.5rem; /* Smaller font on tablet */
      padding: 0 30px;
    }
  }
  
  @media screen and (max-width: 576px) {
    .marquee span {
      font-size: 1.2rem; /* Even smaller font on mobile */
      padding: 0 15px;
    }
  }
  