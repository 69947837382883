.parent-container {
    display: flex;
    justify-content: center; /* Horizontally center the content */
    align-items: center; /* Vertically center the content */
    height: 100vh; /* Adjust as needed */
  }

.loader-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /* height: 30vh; */
}

.afmlogo {
  max-width: 25rem;
  height: 120px;
  /* background-image: url("../img/logo/noBgAfricaMarketLogoStrip.png"); */
  background-size: cover;
  background-repeat: no-repeat;
}

.overlay{
  display: flex;
  justify-content: center; /* Horizontally center the content */
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
  z-index: 999;
}